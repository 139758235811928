<!-- content start -->
<div class="pcoded-content">
  <div class="pcoded-inner-content">
    <div class="main-body">
      <div class="page-wrapper">
        <div [ngClass]="key ? 'forceChangePassword' :''" class="changePassword">
          <h5 *ngIf="!key"><i class="fa fa-lock pr-4"></i>Change Password</h5>
          <h4 *ngIf="key" class="text-center py-2 mt-3"> Change Password </h4>
          <div class="row justify-content-center">
            <div [ngClass]="key ? 'col-md-12' : 'col-md-10 col-12'" class="change-password-form">
              <form [formGroup]="formData" (ngSubmit)="onSubmit()">
                <div class="form-group row">
                  <label [ngClass]="key ? 'col-md-5' : 'col-xl-3 col-md-4 col-lg-4 col-sm-4 col-12'" class="pl-m">old password*</label>
                  <input [type]="oldPasswordTextType ? 'text' : 'password'" class="form-control" id="" formControlName="old_password"
                    [ngClass]="[key ? 'col-md-7 col-12' : 'col-xl-9 col-md-8 col-lg-8 col-sm-8 col-12', (submitted && f.old_password.errors) ? 'is-invalid' :'']">
                  <a class="switch-eye" (click)="toggleOldPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !oldPasswordTextType, 'fa-eye-slash': oldPasswordTextType }"></i></a>
                  <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                    <div *ngIf="f.old_password.errors.required">The Old Password field is required</div>
                  </div>
                  <div *ngIf="errors?.old_password" class="invalid-feedback apiError">
                    <div *ngFor="let item of errors?.old_password;trackBy:trackByIndex;">{{item}}</div>
                  </div>
                  <!-- <span class="fst-normal small text-muted help-text">[A-Z , a-z , 0-9 , @  ] Allowed</span> -->
                </div>
                <div class="form-group row">
                  <label [ngClass]="key ? 'col-md-5' : 'col-xl-3 col-md-4 col-lg-4 col-sm-4 col-12'" class="pl-m">new password*</label>
                  <input [type]="newPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="new_password"
                        [ngClass]="[key ? 'col-md-7 col-12' : 'col-xl-9 col-md-8 col-lg-8 col-sm-8 col-12', (submitted && f.new_password.errors) ? 'is-invalid' :'']">
                        <a class="switch-eye" (click)="toggleNewPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !newPasswordTextType, 'fa-eye-slash': newPasswordTextType }"></i></a>
                  <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                    <div *ngIf="f.new_password.errors.required">The New Password field is required</div>
                    <div *ngIf="f.new_password.errors.minlength">The New Password field must be at least 6 characters
                    </div>
                    <div *ngIf="!f.new_password.errors.minlength && f.new_password.errors.pattern">The New Password must
                      contain at least: 1 uppercase letter, 1 lowercase letter, 1 number</div>
                  </div>
                  <div *ngIf="errors?.new_password" class="invalid-feedback apiError">
                    <div *ngFor="let item of errors?.new_password;trackBy:trackByIndex;">{{item}}</div>
                  </div>
                  <!-- <span class="fst-normal small text-muted help-text">[A-Z , a-z , 0-9 , @  ] Allowed</span> -->
                </div>
                <div class="form-group row">
                  <label [ngClass]="key ? 'col-md-5' : 'col-xl-3 col-md-4 col-lg-4 col-sm-4 col-12'" class="pl-m">confirm password*</label>
                  <input [type]="confirmPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="new_password_re"
                        [ngClass]="[key ? 'col-md-7 col-12' : 'col-xl-9 col-md-8 col-lg-8 col-sm-8 col-12', (submitted && f.new_password_re.errors) ? 'is-invalid' :'']">
                  <a class="switch-eye" (click)="toggleConfirmPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !confirmPasswordTextType, 'fa-eye-slash': confirmPasswordTextType }"></i></a>
                  <div *ngIf="submitted && f.new_password_re.errors" class="invalid-feedback">
                    <div *ngIf="f.new_password_re.errors.required">The Confirm Password field is required</div>
                    <div *ngIf="f.new_password_re.errors.mustMatch">The Confirm Password confirmation does not match
                    </div>
                  </div>
                  <div *ngIf="errors?.new_password_re" class="invalid-feedback apiError">
                    <div *ngFor="let item of errors?.new_password_re;trackBy:trackByIndex;">{{item}}</div>
                  </div>
                </div>

                <div class="btn-group d-flex" *ngIf="key">
                  <button type="submit" class="btn btn-save px-1 rounded-sm">change password</button>
              </div>

                <div class="form-group row justify-content-end" *ngIf="!key">
                  <div class="col-md-10 text-center">
                    <button type="submit" class="btn btn-lg btn-save">change password</button>
                    <button type="button" class="btn btn-lg btn-cancel" [routerLink]="['/dashboard']">cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content start -->