export const environment = {
  production: false,
  panel: "matrixexchadmin_",
  domain: "matrixexch99.com",
  baseUrl: "https://api.matrixexch99.com",
  pollers: {
    oddsData: 600,
    listOddsData: 10000,
    scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  modalClass: 'bookModal',
  isMobile: true,
  isBetOpenEnv : false,
  userList: [
    { role_id: 1, id: 'admin', name: 'Admin', cIds: [] },
    { role_id: 2, id: 'super_master', name: 'Super Master', cIds: [1, 2] },
    { role_id: 3, id: 'master', name: 'Master', cIds: [1, 2] },
    { role_id: 4, id: 'client', name: 'Client', cIds: [1, 2, 3] },
  ],
  hidePartenerShips: [99994],
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  desktopStyles: [],
  mobileStyles: [],
  host: {
    domain: '',
    name: '',
    dName: '',
    ext: '',
    theme: '',
    email: '',
    demoLogin: false,
    pusher: {
      key: '',
      cluster: ''
    },
    ftLivetvData: '',
    mThemePath:[''],
    dThemePath:[''],
  },
  hosts: [
    {
      domain: 'localhost',
      name: 'localhost',
      dName: 'LocalHost',
      ext: '.com',
      theme: 'blue-body',
      email: 'contactmatrixexch9@gmail.com',
      demoLogin: true,
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
        // key: 'c9d022cf8a50a0cd9401',
        // cluster: 'ap2'
      },
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      mThemePath: [],
      dThemePath: [],
    },
    {
      domain: 'matrixexch99.com',
      name: 'matrixexch99',
      dName: 'admin.matrixexch99.com',
      ext: '.com',
      theme: 'blue-body',
      email: 'contactmatrixexch99@gmail.com',
      demoLogin: true,
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      mThemePath: [],
      dThemePath: [],
    }
  ],
};
