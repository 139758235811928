import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  env = environment;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private titleService: Title
  ) {
    const window: any = this.document.defaultView;
    const host = window.location.hostname;
    this.setDynamicStyle(host);
  }

  async setDynamicStyle(host: any) {
    
    // const index = this.env.hosts.findIndex((e: any) => host.includes(e.domain));
    const index = this.env.hosts.findIndex((e: any) => (host === e.dName) || (host === 'www.'+e.dName));
    this.env.host = (index == -1) ? this.env.hosts[0] : this.env.hosts[index];
    if (index == -1) {
      this.env.host.domain = host;
    }
    // if (!localStorage.getItem(`${this.env.panel}theme`)) {
    //   localStorage.setItem(`${this.env.panel}theme`, this.env.host.theme);
    // }
    this.titleService.setTitle(`Welcome to ${this.env.host.name == 'localhost' ? host : this.env.host.name}`);

    if (this.env.host.name != 'localhost') {
      this.env.baseUrl = `https://api.${this.env.host.domain}`;
      this.env.domain = this.env.host.domain;
    }
    // const styleEle = this.document.getElementById('theme') as HTMLElement
    // styleEle.setAttribute('href', this.env.isMobile ? this.env.host.mThemePath : this.env.host.dThemePath)
    const head = this.document.getElementsByTagName('head')[0];
    const stylePath = this.env.isMobile ? this.env.mobileStyles : this.env.desktopStyles
    stylePath.forEach(element => {
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = this.env.isMobile ? `assets/css/mobile/${element}` : `assets/css/desktop/${element}`;
      head.appendChild(style);
    });

    const themePath = this.env.isMobile ? this.env.host.mThemePath : this.env.host.dThemePath
    themePath.forEach(element => {
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${element}`;
      head.appendChild(style);
    });

  }
}
