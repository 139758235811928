import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseClass } from 'src/app/@shared/base-class/base-class';
import { IbaseService } from 'src/app/@shared/base-service/ibase-service';
import { MessageService } from 'src/app/@shared/message/message.service';
import { CommonService } from 'src/app/@shared/services/common.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends BaseClass<any> implements OnInit {
  public key: any;
  public bsModalRef!: BsModalRef;
  formData!: FormGroup;
  submitted = false;
  errors: any;
  oldPasswordTextType = false;
  newPasswordTextType = false;
  confirmPasswordTextType = false;
  @Input() isModal!: any;
  @Output() openLogin = new EventEmitter();

  constructor(
    public service: WebserviceService,
    public _common: CommonService,
    public _message: MessageService,
    public router: Router,
    protected injector: Injector,
    private fb: FormBuilder) {
    super(document);
    if(this.router.url.includes('force-change-password')) {

      this.key = true;
    }
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  ngOnInit(): void {
    this.formData = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      new_password_re: ['', Validators.required],
    }, {
      validator: this._common.mustMatch('new_password', 'new_password_re'),
    });

    if (this.key) {
      this.bsModalRef = this.injector.get<BsModalRef>(BsModalRef);
    }
  }

  get f() {
    return this.formData.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formData.invalid) {
      return;
    }

    let req = this.JSON_to_formData(this.formData.value);
    let url = (this.key) ? this.api.forceChangePass : this.api.changePass;
    
    this.getServices().Post(`${this.env.baseUrl + url}`, req).subscribe(
      (res) => {
        if (res.status == true) {
          this.bsModalRef.hide();
          this.removeLocalStorage();
          this.router.navigate(['/login']);
          this._message.succmessage(res.data.message);
          this.openLogin.emit('');
          if(this.key) {this.bsModalRef.hide();}
        } else {
          this.errors = res.data;
          console.log(this.errors);
          
          this._message.errmessage(res.error.message);
        }
      },
      (err) => {
        console.log(err);
      });
  }

  toggleOldPasswordFieldTextType() {
    this.oldPasswordTextType = !this.oldPasswordTextType;
  }

  toggleNewPasswordFieldTextType() {
    this.newPasswordTextType = !this.newPasswordTextType;
  }

  toggleConfirmPasswordFieldTextType() {
    this.confirmPasswordTextType = !this.confirmPasswordTextType;
  }

}